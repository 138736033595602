.no-border-input {
    input[type='text'],
    input[type='email'],
    input[type='url'],
    input[type='password'],
    input[type='number'],
    input[type='date'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='search'],
    input[type='tel'],
    input[type='time'],
    input[type='week'],
    textarea,
    select {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        padding: 16.5px 14px;
    }
}